<template>
  <div id="app">
    <ModalContainer />

    <div class="logo-container">
      <a href="/"><Logo class="logo" /></a>
    </div>

    <main>
      <router-view />
    </main>

    <Footer />
  </div>
</template>

<script>
  import Footer from 'components/footer';
  import ModalContainer from 'components/modal-container';
  import Logo from 'icons/logo.svg';

  export default {
    name: 'App',
    components: { ModalContainer, Logo, Footer },
    watch: {
      $route (to) {
        window.scrollTo(window.scrollX, 0);
      }
    }
  };

</script>

<style lang="stylus">
  @require '~global/reset'
  @require '~global/fonts'
  @require '~global/typo'

  html
    @extend .t-base
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

  body
    overflow-x: hidden
    position: relative

  a
    text-decoration: none
</style>

<style lang="stylus" scoped>
  @require '~global/helpers'
  @require '~global/typo'

  .logo
    width: 96px

  .logo-container
    padding: 1.5rem 0
    display: flex
    justify-content: center

  main
    width: 100%
    min-height:  max(var(--modal-height), calc(100vh - 206px - 78px))
    display: flex
    flex-direction: column
    align-items: center
    padding: 4rem 2rem 2rem 2rem

  @media (max-width: $tablet)
    main
      padding: 1rem 0 0 0

</style>
