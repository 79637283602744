<template>
  <footer>
    <div class="menu">
      <h1>Mateus B2B</h1>

      <div class="menu-content">
        <p>Öppettider <br>Måndag - Fredag <br>10.00 - 17.00</p>

        <p>
          <br>
          <a href="mailto:tel+4686651060">+46 8 665 10 60</a><br>
          <a href="mailto:mateus@mateuscollection.com">mateus@mateuscollection.com</a>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',

    data () {
      return {

      };
    }
  };
</script>

<style lang="stylus" scoped>
  @require '~global/colors'
  @require '~global/helpers'
  @require '~global/breakpoints'

  footer
    display: flex
    background-color: #414141
    color: $white
    padding: 2.35rem 2.5rem
    letter-spacing: .025em

  h1
    font-weight: 500
    font-size: 1rem
    margin-bottom: 2rem
    letter-spacing: .025em

  p
    line-height: 154%

  .menu-content
    display: flex

  .menu-content p:first-child
    margin-right: 2rem

  @media (max-width: $tablet)
    .menu-content
      flex-direction: column
</style>
