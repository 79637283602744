import Vue from 'vue';
import Router from 'vue-router';
import App from './App.vue';
import routes from './routes';
import AppConfig from 'lib/app-config';
import axios from 'lib/axios';
import store from './store';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

Bugsnag.start({
  apiKey: '2f27fcfb97b8335c37de53163a5b8abc',
  plugins: [new BugsnagPluginVue()]
});

Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);

Vue.config.productionTip = false;

Vue.prototype.apiURL = AppConfig.apiURL;
Vue.prototype.$http = axios;

const router = new Router({
  mode: 'history',
  routes: routes.app
});

Vue.use(Router);

new Vue({
  router,
  store: store.app,
  render: h => h(App)
}).$mount('#app');
